var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({
    display: 'flex',
    color: '#e52a30',
    'font-size': '13px',
    'font-family': 'Arial, Helvetica, sans-serif',
    'align-items': 'center',
    'max-width': _vm.signatureWidth + 'px',
  }),attrs:{"id":"signature-template"}},[_vm._m(0),_c('div',{staticStyle:{"color":"#e52a30","line-height":"1rem"}},[_c('div',{staticStyle:{"font-weight":"bold"},attrs:{"id":"name"}},[_vm._v(_vm._s(_vm.getUserInfo.name))]),_c('div',[_vm._v(_vm._s(_vm.getUserInfo.position))]),_c('div',[_vm._v("Telefon: "+_vm._s(_vm.getUserInfo.phone))]),_c('div',[_c('a',{staticStyle:{"color":"#e52a30"},attrs:{"href":("mailto:" + (_vm.getUserInfo.email))}},[_vm._v(_vm._s(_vm.getUserInfo.email))]),_vm._m(1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding-right":"2rem"}},[_c('a',{attrs:{"href":"https://eestec.ro/"}},[_c('img',{attrs:{"src":"https://i.imgur.com/Hgh0KX2.png"}})])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a',{staticStyle:{"color":"#e52a30"},attrs:{"href":"https://eestec.ro/"}},[_vm._v(" www.eestec.ro ")])])}]

export { render, staticRenderFns }