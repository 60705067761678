import firebase from "firebase/app";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBbdEZBDuJQmjOzVt-3WP1JY-CO5RspQtU",
  authDomain: "signature-generator-301bc.firebaseapp.com",
  databaseURL: "https://signature-generator-301bc.firebaseio.com",
  projectId: "signature-generator-301bc",
  storageBucket: "signature-generator-301bc.appspot.com",
  messagingSenderId: "746739853879",
  appId: "1:746739853879:web:efec10c8b3d3bec09cedb2",
  measurementId: "G-9317Q526T8",
};

firebase.initializeApp(firebaseConfig);

export { firebase };
