import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUserInfo: {
      name: "",
      position: "Membru Junior",
      phone: "",
      email: "",
      photo: ""
    },
    positions: [
      "Membru Junior",
      "Membru Senior",
      "Membru Alumni",
      "Membru de Onoare",
      "Președinte",
      "Trezorier",
      "Contact Person",
      "Vicepreședinte PR",
      "Vicepreședinte HR",
      "Vicepreședinte FR",
      "Vicepreședinte IT",
    ],
  },
  getters: {
    getUserInfo: (state) => {
      return state.currentUserInfo;
    },
    getPositions: (state) => {
      return state.positions;
    },
  },
  mutations: {
    addUserInfo(state, payload) {
      state.currentUserInfo.name = payload.name ? payload.name : "";
      state.currentUserInfo.email = payload.email ? payload.email : "";
      state.currentUserInfo.photo = payload.photo ? payload.photo : "";
    },
  },
  actions: {},
  modules: {},
});
