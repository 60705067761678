<template>
  <div id="login-page">
    <h1> Signature Generator EESTEC București</h1>
    <img src="../assets/logo-eestec.png">
    <button @click="login">Login</button>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/auth";

import { mapMutations } from "vuex";

export default {
  data() {
    return {};
  },
  methods: {
    ...mapMutations(["addUserInfo"]),
    login() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          const userInfo = result.additionalUserInfo.profile;
          const payload = {
            name: userInfo.name,
            email: userInfo.email,
            photo: userInfo.picture
          };
          if (userInfo.hd === "eestec.ro") {
            this.addUserInfo(payload);
            this.$router.push("/home");
          } else {
            alert("Only EESTEC Bucuresty members can use this application!");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

img {
  max-width: 400px;
}

button {
  padding: 1em 2em;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  color: white;
  background-color: #e52a30;
  outline: 2px solid white;
  transition: outline-offset 200ms ease;
  min-width: 20ch;
  &:hover {
    cursor: pointer;
    outline-offset: -4px;
  }
}
</style>
