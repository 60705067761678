<template>
  <div
    id="signature-template"
    :style="{
      display: 'flex',
      color: '#e52a30',
      'font-size': '13px',
      'font-family': 'Arial, Helvetica, sans-serif',
      'align-items': 'center',
      'max-width': signatureWidth + 'px',
    }"
  >
    <div style="padding-right: 2rem">
      <a href="https://eestec.ro/"
        ><img src="https://i.imgur.com/Hgh0KX2.png"
      /></a>
    </div>
    <div style="color: #e52a30; line-height: 1rem">
      <div id="name" style="font-weight: bold;">{{ getUserInfo.name }}</div>
      <div>{{ getUserInfo.position }}</div>
      <div>Telefon: {{ getUserInfo.phone }}</div>
      <div>
        <a :href="`mailto:${getUserInfo.email}`" style="color: #e52a30;">{{
          getUserInfo.email
        }}</a>
        <div>
          <a href="https://eestec.ro/" style="color: #e52a30;">
            www.eestec.ro
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["signatureWidth"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUserInfo"]),
  },
};
</script>

<style lang="scss" scoped>
::selection {
  background: transparent;
}
</style>
