<template>
  <div id="signature-input">
    <div>
      <label for="name"> Name: </label>
      <input
        type="text"
        id="name"
        v-model="getUserInfo.name"
        :readonly="!isCustom"
      />
    </div>
    <div>
      <label for="position"> Position: </label>
      <select id="position" v-model="getUserInfo.position" v-if="!isCustom">
        <option
          v-for="(position, index) in getPositions"
          :key="index"
          :value="position"
        >
          {{ position }}</option
        >
      </select>
      <input
        id="position-input"
        v-model="getUserInfo.position"
        v-if="isCustom"
      />
    </div>
    <div>
      <label for="phone"> Phone Number: </label>
      <input
        type="text"
        id="phone"
        v-model="getUserInfo.phone"
        placeholder="Insert your phone number"
      />
    </div>
    <div>
      <label for="email"> Email: </label>
      <input
        type="text"
        id="email"
        v-model="getUserInfo.email"
        :readonly="!isCustom"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: ["isCustom"],
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getUserInfo", "getPositions"]),
  },
};
</script>

<style lang="scss" scoped>
#signature-input {
  max-width: 500px;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.8rem;
  gap: 1rem;
  padding: 1rem;
  background-color: lightgrey;
  border-radius: 0.5rem;
  div {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    * {
      flex-basis: 100%;
    }
  }
}

input,
select {
  border-radius: 0.2rem;
  outline: none;
  border: none;
  padding-left: 0.5rem;
}

input:read-only,
input::placeholder {
  opacity: 0.9;
  color: #8e8e8e;
}

@media screen and (max-width: 460px) {
  #signature-input {
    font-size: 1rem;
    width: 170%;
    div {
      input,
      select {
        flex-basis: 140%;
      }
    }
  }
}
</style>
