<template>
  <div id="home">
    <h1>Signature Generator EESTEC București</h1>
    <img class="user-photo" :src="$store.state.currentUserInfo.photo" />
    <div class="fields-wrapper">
      <div>
        <SignatureInput :isCustom="isCustom" />
        <button class="change-btn" @click="isCustom = !isCustom">
          Custom Input
        </button>
      </div>
      <div>
        <div class="slider-wrapper">
          <label for="width-slider">
            Signature Width: {{ rangeValue }}px
          </label>
          <input
            id="width-slider"
            type="range"
            :min="rangeMin"
            :max="rangeMax"
            v-model="rangeValue"
          />
        </div>
        <SignatureTemplate :signatureWidth="rangeValue" />
        <button class="cpy-btn" data-clipboard-target="#signature-template">
          {{ cpyBtnMessage }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
//importing components
import SignatureInput from "@/components/SignatureInput";
import SignatureTemplate from "@/components/SignatureTemplate";

import ClipboardJS from "../../node_modules/clipboard/dist/clipboard";

export default {
  name: "Home",
  components: {
    SignatureInput,
    SignatureTemplate,
  },
  data() {
    return {
      isCustom: false,
      cpyBtnMessage: "Copy Signature",
      rangeMin: 300,
      rangeMax: 600,
      rangeValue: 400,
    };
  },
  created() {
    const copyButton = new ClipboardJS(".cpy-btn");

    copyButton.on("success", () => {
      this.cpyBtnMessage = "Copied!";
      setTimeout(() => {
        this.cpyBtnMessage = "Copy Signature";
      }, 2000);
    });
  },
};
</script>

<style lang="scss" scoped>
#home {
  display: flex;
  flex-direction: column;
  align-items: center;
}
h1 {
  width: 100%;
  text-align: center;
}

.user-photo {
  border-radius: 50%;
  border: 3px solid #e52a30;
}

.fields-wrapper {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: 4% auto;
  font-family: Arial, Helvetica, sans-serif;
  gap: 2rem;
  & > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    min-width: 53ch;
  }
}

button {
  padding: 1em 2em;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  color: white;
  background-color: #e52a30;
  outline: 2px solid white;
  transition: outline-offset 200ms ease;
  margin: 0 20%;
  min-width: 20ch;
  align-self: flex-end;
  &:hover {
    cursor: pointer;
    outline-offset: -4px;
  }
}

.slider-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}

#width-slider {
  width: 100%;
  &:hover {
    cursor: pointer;
  }
  &:active {
    cursor: grabbing;
  }
}

@media screen and (max-width: 1000px) {
  .fields-wrapper {
    flex-wrap: wrap;
    gap: 2rem;
    & > div {
      min-width: 30ch;
    }
  }

  .slider-wrapper {
    margin: 0 30%;
  }

  button {
    margin: 0 30%;
  }
}

@media screen and (max-width: 460px) {
  button {
    font-size: 14px;
  }
}
</style>
